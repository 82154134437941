var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"9f460904ee8d95863a2ff25feedba4fb9eb29401"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs'

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN

const integrations = []

if (
  !process.env.NODE_ENV ||
  (process.env.NODE_ENV && process.env.NODE_ENV !== 'development')
) {
  Sentry.init({
    dsn:
      SENTRY_DSN ||
      'https://90113b59c03f454db60df5ed5687afa3@o305004.ingest.sentry.io/1760471',
    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 1.0,
    replaysOnErrorSampleRate: 1.0,
    integrations: [
      new Sentry.Replay({
        maskAllText: false,
        maskAllInputs: false,
        blockAllMedia: false,
      }),
    ],
    beforeSend(event, hint) {
      const error = hint.originalException
      if (error && error.message && error.message.match(/prototype/i)) {
        return null
      } else return event
    },
  })
}
