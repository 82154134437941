import Link from 'next/link'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import Book from './book'

const Header = dynamic(() => import('./header'))
const Footer = dynamic(() => import('./footer'))
const AddedItem = dynamic(() => import('./atoms/added-item'))
const BottomBanner = dynamic(() => import('./atoms/bottom-banner'))
const FixedBanner = dynamic(() => import('./atoms/fixed-banner'))
const BlogList = dynamic(() => import('./atoms/blog-list'))
const ConditionsList = dynamic(() => import('./atoms/conditions'))
const Promo = dynamic(() => import('./molecules/promo'), {
  ssr: false,
})

const promos = {
  // '1': {
  //   title: 'January Specials',
  //   text: '25% off body treatments and packages (excludes injectables) with code',
  //   code: 'BODY25'
  // },
  // '2': {
  //   title: 'Summer Specials',
  //   text: '25% off dermal fillers (excluding packages) this July with code',
  //   code: 'JULY25',
  // },
  // '3': {
  //   title: 'Spring Sale',
  //   text: 'Get An Additional 25% Off Listed Laser Prices',
  //   code: 'MAYLASER',
  // },
}

const maps = {
  '/dermal-fillers/': '2',
  '/lip-enhancement/': '2',
  '/cheek-enhancement/': '2',
  '/jawline-enhancement/': '2',
  '/non-surgical-rhinoplasty/': '2',
  '/chin-enhancement/': '2',
  '/nasolabial-folds/': '2',
  '/tear-trough/': '2',
  '/laser-hair-removal/': '3',
  '/laser-hair-removal-for-dark-skin/': '3',
  '/laser-hair-removal-for-asian-skin/': '3',
  '/laser-hair-removal-for-indian-skin/': '3',
}

export default function Layout({ children, cls = '' }) {
  const router = useRouter()
  const promo =
    maps[router.asPath] && promos[maps[router.asPath]]
      ? promos[maps[router.asPath]]
      : null

  return <>
    {/* ACTIVE TOP BANNER  */}
    <FixedBanner router={router} />
    <Header />
    {/* <div className="bg-white shadow border-b">
      <div className="mx-auto max-w-7xl py-3 px-3 sm:px-6 lg:pl-8 lg:-pr-1">
        <div className="flex flex-wrap items-center justify-between">
          <div className="flex flex-1 items-center">
            <p className="ml-3 font-medium text-brand-600">
              <span className="md:hidden">
                Black Friday is here: 50% off all treatments!
              </span>
              <span className="hidden md:inline">
              Black Friday is here: 50% off all treatments!
              </span>
            </p>
          </div>
          <div className="order-3 mt-2 w-full flex-shrink-0 sm:order-2 sm:mt-0 sm:w-auto">
            <Link
              href="/treatments/"
              passHref
              className="w-full md:w-auto flex items-center justify-center rounded-md border border-transparent bg-brand-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-brand-50">
              
                Book a treatment
              
            </Link>
          </div>
        </div>
      </div>
    </div> */}
    <main className={cls ? cls : ''}>
      {/* ACTIVE BANNER IN PAGES*/}
      {/*{true &&
        !router.pathname.includes('checkout') &&
        !router.pathname.includes('booking') &&
        !router.pathname.includes('thanks') &&
        !router.pathname.includes('sign') &&
        !router.pathname.includes('pass') &&
        !router.pathname.includes('account') && <Promo {...promo} />}
         DEACTIVE BANNER */}
      {children}
    </main>
    <Footer />
    <div
      aria-live="assertive"
      className="fixed inset-0 flex items-end justify-center px-4 py-6 pointer-events-none sm:p-6 sm:items-start sm:justify-end"
      style={{ zIndex: 999 }}
    >
      <AddedItem />
    </div>
    {/* <BottomBanner /> */}
    <BlogList />
    <ConditionsList />
    <Book />
  </>;
}
